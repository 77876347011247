// extracted by mini-css-extract-plugin
export var aboutus = "aboutus-module--aboutus--+tC4i";
export var aboutus__col1 = "aboutus-module--aboutus__col1--EFt0K";
export var aboutus__container = "aboutus-module--aboutus__container--Xuu0b";
export var advisor = "aboutus-module--advisor---xv9Q";
export var advisor__container = "aboutus-module--advisor__container--h1V1U";
export var advisor__double_line = "aboutus-module--advisor__double_line--ErfDd";
export var advisor__marque = "aboutus-module--advisor__marque--uU8ho";
export var advisormarquee = "aboutus-module--advisormarquee--J3Nag";
export var angel = "aboutus-module--angel--Z+SWN";
export var angel__card = "aboutus-module--angel__card--Q44Ap";
export var angel__card__img = "aboutus-module--angel__card__img--9IIku";
export var angel__card__text = "aboutus-module--angel__card__text--J-z35";
export var angel__col2 = "aboutus-module--angel__col2--KgdES";
export var angel__container = "aboutus-module--angel__container--g7yse";
export var angel__marque = "aboutus-module--angel__marque--isj3J";
export var angel__row = "aboutus-module--angel__row--DzWiN";
export var angelmarquee = "aboutus-module--angelmarquee--+5Y4B";
export var circel = "aboutus-module--circel--fVlkq";
export var details = "aboutus-module--details--cUS+D";
export var founder = "aboutus-module--founder--gOI4B";
export var founder__container = "aboutus-module--founder__container--gthhG";
export var founder__img = "aboutus-module--founder__img--yrlDd";
export var hero_img1 = "aboutus-module--hero_img1--AcNRX";
export var heroimg = "aboutus-module--heroimg--7NLDn";
export var hide_truncate = "aboutus-module--hide_truncate--htj4E";
export var instimarquee = "aboutus-module--instimarquee--JvxfT";
export var institutional = "aboutus-module--institutional--3m6y3";
export var institutional__card = "aboutus-module--institutional__card--xee0g";
export var institutional__container = "aboutus-module--institutional__container--Y5iW1";
export var institutional__double_line = "aboutus-module--institutional__double_line--rYZwr";
export var joinus = "aboutus-module--joinus--r2GnQ";
export var joinus__buttons = "aboutus-module--joinus__buttons--2r+so";
export var joinus__buttons__opening = "aboutus-module--joinus__buttons__opening--W6R0r";
export var marquee__first = "aboutus-module--marquee__first--jyfzG";
export var marquee__inners = "aboutus-module--marquee__inners--DCdYu";
export var readmore_btn = "aboutus-module--readmore_btn--2Aown";
export var show_truncate = "aboutus-module--show_truncate--aKyLu";
export var story = "aboutus-module--story--bUyJO";
export var story__container = "aboutus-module--story__container--9sslm";
export var story__line = "aboutus-module--story__line--nvQGn";
export var story__row = "aboutus-module--story__row--b1GZl";