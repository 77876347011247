import React from 'react'
import { Container, Row, Col, Button } from "react-bootstrap"
import { StaticImage } from 'gatsby-plugin-image'
import Marquee from "react-fast-marquee";
// local import
import '../../components/Common/Carousels/style.scss'
import * as classes from '../../styles/aboutus.module.scss'
import Layout from "../../components/Common/Layout"
import Seo from "../../components/Common/Seo"

import circel from '../../assets/images/about/circle.png'
import bold from '../../assets/images/about/bold.png'
import alpha from '../../assets/images/about/Alpha.png'
import beenext from '../../assets/images/about/Beenext.png'
import blume from '../../assets/images/about/blume.png'
import doubleLine from '../../assets/images/about/double-line.svg'
import VibhoreSinghal from '../../assets/images/about/Vibhore-Singhal.png'
import AbhishekGoyal from '../../assets/images/about/Abhishek-Goyal.png'
import AprameyaRadhakrishna from '../../assets/images/about/Aprameya-Radhakrishna.png'
import DeepakSinghAhlawat from '../../assets/images/about/Deepak-Singh-Ahlawat.png'
import IndraneelMukerji from '../../assets/images/about/Indraneel-Mukerji.png'
import ManishJethani from '../../assets/images/about/Manish-Jethani.png'
import MohitKumar from '../../assets/images/about/Mohit-Kumar.png'
import NitinGupta from '../../assets/images/about/Nitin-Gupta.png'
import RohitMA from '../../assets/images/about/Rohit-M-A.png'
import VatsalSinghal from '../../assets/images/about/Vatsal-Singhal.png'
import VenkatKrishnan from '../../assets/images/about/Venkat-Krishnan.png'
import KierstenGaffney from '../../assets/images/about/Kiersten-Gaffney.png'
import JoanKuehl from '../../assets/images/about/Joan-Kuehl.png'
// import RakeshSingh from '../../assets/images/about/Rakesh-Singh.png'
import RevantBhate from '../../assets/images/about/Revant_Bhate.png'
import YashJain from '../../assets/images/about/Yash_Jain.png'
import founder from '../../assets/images/about/founder.png'
import aboutHeroImg from '../../assets/images/about/hero.png'
import linkedin from "../../assets/images/footer/linkedin.png"
import ScrollContainer from 'react-indiana-drag-scroll'
import { useState } from 'react';
import { Helmet } from 'react-helmet';

const RectangleBox = ({ url }) => {
  return (
    <div className={`${classes.institutional__card}`}>
      <img src={url} alt={url} />
    </div>
  )
}

const SquareBox = ({ url, name, position }) => {
  return (
    <div className={`${classes.angel__card}`}>
      <div className={`${classes.angel__card__img}`}>
        <img src={url} alt={url} />
      </div>
      <div className={`${classes.angel__card__text}`}>
        <h4>{name}</h4>
        <p>{position}</p>
      </div>
    </div>
  )
}

const AboutUs = () => {
  const [scroll, setScroll] = useState(true);
  const [intiScroll, setIntiScroll] = useState(true);
  const [advisorScroll, setAdvisorScroll] = useState(true);
  const [truncate, setTruncate] = React.useState(true)
  const toggleText = () => {
    setTruncate(!truncate)
  }
  const onMouseEnter = (e) => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === `${classes.angelmarquee}`) {
        anim.pause()
      }
    });
    setScroll(false)
  }
  const onMouseLeave = (e) => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === `${classes.angelmarquee}`) {
        anim.play()
      }
    });
    setScroll(true)
  }
  const onEndScroll = () => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === `${classes.angelmarquee}`) {
        anim.cancel();
        anim.play();
      }
    });
  }
  const onIntiEndScroll = () => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === `${classes.instimarquee}`) {
        anim.cancel();
        anim.play();
      }
    });
  }
  const onAdvisorEndScroll = () => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === `${classes.advisormarquee}`) {
        anim.cancel();
        anim.play();
      }
    });
  }
  return (
    <>
      <Layout>
        <Seo
          title="Lucidity | What is Lucidity and what do we strive for?"
          description={`We’re on a mission to build the smart storage of tomorrow! We dedicate ourselves to help our customers achieve the true potential of cloud, making their life simpler, and to tap into inherent cloud-native functionalities.`}
          canonicalUrl="https://www.lucidity.cloud/about-us"
        />
        <section className={classes.aboutus}>
          <Container className={classes.aboutus__container}>
            <Row>
              <Col lg="7" className={classes.aboutus__col1}>
                <h1>We’re on a mission to build the smart storage of tomorrow!</h1>
                <p>Lucidity is about helping our customers achieve the full potential of the cloud.
                  We are dedicating ourselves to help enterprises move data to cloud storage as lucidity makes
                  their life simpler, and helps them tap into cloud-native functionalities.</p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pb-0'>
          <div className={classes.heroimg}>
            <div className={classes.heroimg__bg} />
            <img src={aboutHeroImg} alt={'hero-image'} className={`w-100 ${classes.hero_img1}`} />
          </div>
        </section>
        <section className={classes.story}>
          <div className={classes.story__line} />
          <Container className={classes.story__container}>
            <h2>Our Story</h2>
            <img src={circel} alt={'circle'} className={classes.circel}/>
            <Row className={classes.story__row}>
              <Col lg="6">
                <span>Today’s businesses are rapidly moving towards being cloud-first, and once COVID struck, the rate of cloud adoption within enterprises also gained speed. 8 out of 10 organizations, today, resort to lift and shift with no changes to their underlying architecture. This limits their capability to utilize cloud-native functionalities, leading to over-provisioning, performance limitations and budget overruns.</span>
              </Col>
              <Col lg="6">
                <div className={`${truncate ? classes.hide_truncate : classes.show_truncate}`}>
                  <p>We saw an opportunity here and the need for an intelligent orchestration solution that can help them bridge this gap. A solution which can provide all the benefits of cloud native applications sans any modification to application. A solution which truly embodies the next phase of DevOps, which industry calls as “NoOps”.</p>
                </div>
                <Button variant="outline" className={classes.readmore_btn} onClick={toggleText}>{truncate ? 'Read More' : 'Read Less'}</Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={classes.founder}>
          <Container className={classes.founder__container}>
            <Row>
              <Col lg="6">
                <h2>Meet the founders</h2>
                <p>We bring more than a decade of experience working with some of the top tech giants in the world to build and deliver innovative products. Our backgrounds are varied, but we share a passion for solving big problems and delivering significant value to customers and investors.</p>
              </Col>
            </Row>
          </Container>
          <div className={classes.founder__img}>
            <img src={founder} alt="founders" />
          </div>
          <Container className={classes.founder__container}>
            <Row className='mt-5'>
              <Col lg="6">
                <div className={`${classes.details} mb-4 m-sm-0`}>
                  <span className={'text-primary'}>Vatsal Rastogi </span><span>drives our technical vision and manages engineering and development. As a software developer involved in creating Microsoft Azure, Vatsal saw the vast potential of cloud storage and confronted the complexity users face in achieving it. His conclusion: We can do better. (And we have!)</span>
                  <a href="https://www.linkedin.com/in/vatsal-rastogi-2b468468/" target="_blank">
                    <img src={linkedin} alt="linkedin" className='d-block mt-2' height={40} />
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <div className={`${classes.details} w-sm-75`}>
                  <span className={'text-primary'}>Nitin Bhadauria </span> <span>keeps the business running smoothly and handles sales and customer relations. Both a technologist and an entrepreneur, Nitin has launched several startups and managed a sales team selling in more than 30 countries in North America, Europe, and Southeast Asia.</span>
                  <a href="https://www.linkedin.com/in/nitinbhadauria/" target="_blank">
                    <img src={linkedin} alt="linkedin" className='d-block mt-2' height={40} />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={classes.institutional}>
          <Container className={classes.institutional__container}>
            <h2>Our Institutional investors</h2>
            <img src={doubleLine} alt={'line'} className={classes.institutional__double_line} />
            <div className='d-none d-lg-block'>
              <Marquee speed={33} gradient={false} pauseOnHover={true}>
                <RectangleBox url={bold} />
                <RectangleBox url={alpha} />
                <RectangleBox url={beenext} />
                <RectangleBox url={blume} />
              </Marquee>
            </div>
            <div
              className='d-block d-lg-none'
              onMouseEnter={() =>setIntiScroll(false)}
              onMouseMove={() => setIntiScroll(false)}
              onMouseLeave={() => setIntiScroll(true)}
              onTouchMove={() => setIntiScroll(false)}
              onTouchEnd={() => setIntiScroll(true)}
            >
              <ScrollContainer className={`${classes.marquee__first} d-flex`} vertical={false}
                 onEndScroll={onIntiEndScroll} onClick={onIntiEndScroll}>
                <div
                id="test"
                  style={{ animationPlayState: `${intiScroll ? 'running' : 'paused'}` }}
                  className={`${classes.marquee__inners} d-flex`}>
                  <RectangleBox url={bold} />
                  <RectangleBox url={alpha} />
                  <RectangleBox url={beenext} />
                  <RectangleBox url={blume} />
                </div>
              </ScrollContainer>
            </div>
          </Container>
        </section>

        <section className={classes.angel}>
          <Container className={classes.angel__container}>
            <Row className={classes.angel__row}>
              <Col lg="8" md={12} className='order-lg-1 order-md-2 order-2'>
                <div
                  onMouseEnter={onMouseEnter}
                  onMouseMove={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onTouchMove={onMouseEnter}
                  onTouchEnd={onMouseLeave}
                >
                  <ScrollContainer className={`${classes.marquee__first} d-flex`} vertical={false}
                    onEndScroll={onEndScroll} onClick={onEndScroll}>
                    <div
                    id="angel"
                      style={{ animationPlayState: `${scroll ? 'running' : 'paused'}` }}
                      className={`${classes.marquee__inners} d-flex`}>
                      <SquareBox url={VenkatKrishnan} name={'Venkatram Krishnan'} position={'CEO, NuWare'} />
                      <SquareBox url={RohitMA} name={'Rohit M A'} position={'MD, CloudNine'} />
                      <SquareBox url={AprameyaRadhakrishna} name={'Aprameya Radhakrishna'} position={'Founder, Taxiforsure'} />
                      <SquareBox url={AbhishekGoyal} name={'Abhishek Goyal'} position={'Co-founder, Tracxn'} />
                      <SquareBox url={NitinGupta} name={'Nitin Gupta'} position={'CTO, Milkbasket'} />
                      <SquareBox url={VatsalSinghal} name={'Vatsal Singhal'} position={'Co-founder, Ultrahuman'} />
                      <SquareBox url={MohitKumar} name={'Mohit Kumar'} position={'Founder & CEO, Ultrahuman'} />
                      <SquareBox url={DeepakSinghAhlawat} name={'Deepak Singh'} position={'Founder, Anzy Careers'} />
                      <SquareBox url={VibhoreSinghal} name={'Vibhor Singhal'} position={'President, Tracxn'} />
                      <SquareBox url={IndraneelMukerji} name={'Indraneel Mukerji '} position={'SVP, Sales NuWare'} />
                      <SquareBox url={ManishJethani} name={'Manish Jethani'} position={'Co-founder, Hevo data'} />
                      <SquareBox url={RevantBhate} name={'Revant Bhate'} position={'Co-founder, MosaicWellness'} />
                      <SquareBox url={YashJain} name={'Yash Jain'} position={'General Partner, Sparrow Capital'} />
                    </div>
                  </ScrollContainer>
                </div>

              </Col>
              <Col lg="4" md={12} className={`${classes.angel__col2} order-lg-2 order-md-1 order-1`}>
                <h2>Our angel investors</h2>
                <img src={doubleLine} alt={'line'} className={classes.angel__double_line} />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={classes.advisor}>
          <Container className={classes.advisor__container}>
            <h2>Our Advisors</h2>
            <img src={doubleLine} alt={'line'} className={classes.advisor__double_line} />
            <div className='d-none d-md-block'>
              <Marquee pauseOnClick={true} speed={0} gradient={false} pauseOnHover={true} className={classes.advisor__marque}>
                <SquareBox url={KierstenGaffney} name={'Kiersten Gaffney'} position={'CMO, Codefresh'} />
                {/* <SquareBox url={RakeshSingh} name={'Rakesh singh'} position={'Managing Principal, BeyondCX LLC'} /> */}
                <SquareBox url={JoanKuehl} name={'Joan Kuehl'} position={'Ex-CIO, Elevate'} />
              </Marquee>
            </div>
            <div
              onMouseEnter={() =>setAdvisorScroll(false)}
              onMouseMove={() => setAdvisorScroll(false)}
              onMouseLeave={() => setAdvisorScroll(true)}
              onTouchMove={() => setAdvisorScroll(false)}
              onTouchEnd={() => setAdvisorScroll(true)}
              className="d-block d-md-none"
            >
              <div className={`d-flex`}>
                <div
                  // style={{ animationPlayState: `${advisorScroll ? 'running' : 'paused'}` }}
                  className={`d-flex`}>
                  <SquareBox url={KierstenGaffney} name={'Kiersten Gaffney'} position={'CMO, Codefresh'} />
                  {/* <SquareBox url={RakeshSingh} name={'Rakesh singh'} position={'Managing Principal, BeyondCX LLC'} /> */}
                  <SquareBox url={JoanKuehl} name={'Joan Kuehl'} position={'Ex-CIO, Elevate'} />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className={classes.joinus}>
          <Container>
            <Row>
              <Col lg="7" className='text-center'>
                <StaticImage src='../../assets/images/about/spiral.svg' alt='logo'/>
              </Col>
              <Col lg="5" className='my-auto'>
                <h3>Careers</h3>
                <p>Join our team to help reshape the DevOps landscape and make No-Ops for cloud storage a reality.
                  We’re always looking for professionals who share our passion for innovation and contribution. Join Us.</p>
                <div className={classes.joinus__buttons}>
                  <Button variant="outlined" className={`${classes.joinus__buttons__opening} btn-primary`}>
                    <a href="https://www.linkedin.com/company/lucidity-cloud/jobs" target="_blank">
                      View current openings
                    </a>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default AboutUs